import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import CookieConsent from "react-cookie-consent";


function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      {children}
      <footer className="bg-gray-800">
        <CookieConsent   buttonText="Allow cookies">This website uses cookies to enhance the user experience.</CookieConsent>
        <nav className="flex justify-between max-w-4xl p-4 mx-auto text-xs md:p-1">
          <p>
            <Link className="text-xs text-white no-underline" to="/legal-notice/">
              Legal Notice
            </Link>
            !
            <Link className="text-xs text-white no-underline" to="/privacy-policy/">
              Privacy Policy
            </Link>
            !
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
